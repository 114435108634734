import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { KalenderBaliHomeComponent } from './kalender-bali/home/home.component';
import { KalenderBaliPolicyComponent } from './kalender-bali/policy/policy.component';

const routes: Routes = [
  { path: '', redirectTo: '/kalender-bali', pathMatch: 'full' },
  { path: 'kalender-bali', component: KalenderBaliHomeComponent },
  { path: 'kalender-bali/policy', component: KalenderBaliPolicyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
